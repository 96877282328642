import React, { useRef, useEffect } from "react";
import JoditEditor from "jodit-react";



const config = {
  buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
};

const RichTextEditor = ({initialValue,setBlogBody }) => {
  const editor = useRef(null);


  useEffect(() => {
    const joditDiv = document.getElementsByClassName('jodit-workplace')

    try{
    joditDiv.style.cssText = `
        height:1200px;
    `
    }catch(e){
      console.log(e)

    }
  }, )
  
  return (
    <JoditEditor
      ref={editor}
      value={initialValue}
      height="800"
      //   onBlur={(newContent) => getValue(newContent)}
      onChange={(newContent) => setBlogBody(newContent)}
    />
  );
};

export default RichTextEditor;
