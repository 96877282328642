import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import '../css/contactUs.css'
import { apiUrl } from '../api' 
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {FLASH_ERROR, FLASH_SUCCESS } from '../constants/actionTypes'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import styled from 'styled-components'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

function ContactUs() {
    const contactIntial = {firstName:'', lastName:'', company:'', email:'', phone:'', country: '', message:'', checkbox:false}

    const [contactData, setContactData] = useState(contactIntial)
    const [formErrors, setFormErrors] = useState({})
    const [submitFlag, setSubmitFlag] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(validate(contactData))
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setContactData({...contactData, [name]: value})
        
    }

    const submitContact = async () => {
        try{
            const config = {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                },
              };
            const {data} = await axios.post(`${apiUrl}/contactUs`, {contactData},config)
            if(data.type === "success"){
                setContactData(contactIntial)
                dispatch({type:FLASH_SUCCESS, payload:data.message})
            }
            else
                dispatch({type:FLASH_ERROR, payload:data.message})   
        }
        catch(err){
            dispatch({type:FLASH_ERROR, payload:err.message})
        }
    }

    const validate = (formValues) => {
        let errors = {}
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i;
        if(formValues.firstName.length === 0){
            errors = {...errors, firstName:"First Name is required!"}
        }
    
        if(formValues.lastName.length === 0){
            errors = {...errors, lastName:"Last Name is required!"}
        }
    
        if(formValues.company.length === 0){
            errors = {...errors, company:"Company name is required!"}
        }
    
        if(formValues.email.length === 0){
            errors = {...errors, email:"Email is required!"}
        }
        else if(!emailRegex.test(formValues.email)){
            errors = {...errors, email:"Please enter a valid email!"}
        }
        // if(formValues.phone.length === 0){
        //     errors = {...errors, phone:"Phone is required!"}
        // }
        // else if(!phoneRegex.test(formValues.phone)){
        //     errors = {...errors, phone:"Enter a valid phone number!"}
        // }
        if(formValues.country.length === 0){
            errors = {...errors, country:"Country is required!"}
        }
        if(formValues.message.length === 0){
            errors = {...errors, message:"Message is required!"}
        }
        if(formValues.message.length > 200){
            errors = {...errors, message:"Word limit exceed!"}
        }
        if(!formValues.checkbox){
            errors = {...errors, checkbox:"Agree with terms and conditions to proceed!"}
        }
        if(Object.keys(errors).length === 0){
            submitContact()
        }
    
        return errors
    }


    return (
    <div className='contact-us-page'>
        <div className='contactUs-banner '>
            <div className='contactUs-content'>
                <div className='inner-banner-title'><span>CONTACT US</span></div>
                <div>Any questions or remarks? <br />Just drop us a message!</div>
            </div>
        </div>
        <div className='row m-0'>
                <div className='col-12 col-md-7 pt-3 contactUs-first-column' style={{background:"#F5F5F5"}}>
                        <form onSubmit={handleSubmit} className='needs-validation' novalidate>
                            <div className='row'>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels '>First Name <span style={{color:"#CE0002"}}>*</span></div>
                                    <input type="text" className='contact-us-input ' name="firstName"  value={contactData.firstName} onChange={handleChange}placeholder='Enter First Name' />
                                    <div className="contactus-form-error">{formErrors.firstName?formErrors.firstName:null}</div>                                
                                </div>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels '>Last Name <span style={{color:"#CE0002"}}>*</span></div>
                                    <input type="text" className='contact-us-input ' name="lastName" value={contactData.lastName} onChange={handleChange} placeholder='Enter Last Name' />
                                    <div className="contactus-form-error">{formErrors.lastName?formErrors.lastName:null}</div>                                
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels'>Company <span style={{color:"#CE0002"}}>*</span></div>
                                    <input type="text" className='contact-us-input' name="company" value={contactData.company} onChange={handleChange} placeholder='Enter Company Name' /> 
                                    <div className="contactus-form-error">{formErrors.company?formErrors.company:null}</div>                                
                                </div>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels'>Email <span style={{color:"#CE0002"}}>*</span></div>
                                    <input type="text" className='contact-us-input' name="email" value={contactData.email} onChange={handleChange}  placeholder='Enter Email' />
                                    <div className="contactus-form-error">{formErrors.email?formErrors.email:null}</div>                                
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels'>Phone</div>
                                    <input type="text"  className='contact-us-input' name="phone" value={contactData.phone} onChange={handleChange} placeholder='Enter Phone' />
                                    <div className="contactus-form-error">{formErrors.phone?formErrors.phone:null}</div>                                
                                </div>
                                <div className='col-12 mt-3 mt-sm-2 col-sm-6'>
                                    <div className='input-labels'>Country <span style={{color:"#CE0002"}}>*</span></div>
                                    <input type="text" className='contact-us-input' name="country" value={contactData.country} onChange={handleChange}  placeholder='Enter Country' />
                                    <div className="contactus-form-error">{formErrors.country?formErrors.country:null}</div>                                
                                </div>
                            </div>  
                            <div className='row'>
                                <div className='col-12 my-3 mt-sm-2 '>
                                    <div className='input-labels'>Message<span style={{color:"#CE0002"}}>*</span></div>
                                    <textarea style={{height:"300px"}} name="message" value={contactData.message} onChange={handleChange}  className='contact-us-input' placeholder="Tell us more..." />
                                    <div className="contactus-form-error">{formErrors.message?formErrors.message:null}</div>                                
                                </div>
                            </div>
                            <div className=' privacy-policy' >
                                <input type="checkbox" onChange={(e) => setContactData({...contactData, checkbox:e.target.checked})} name="terms"/>&nbsp; I agree to the &nbsp;<a href='/privacypolicy' id="privacyLink" target="_blank"><u>Privacy Policy and Other T&C.</u></a>
                            </div>
                            <div className="contactus-form-error mb-4 d-flex justify-content-end">{formErrors.checkbox?formErrors.checkbox:null}</div>                                 

                            <div className='d-flex justify-content-center'>
                                <button type="submit" className='submit-button' >SEND</button>
                            </div>
                        </form>
                </div>
            <div className='col-12 col-md-5 contactUs-second-column'>
                <div className='contactUs-second-column-div'>
                    <div className='mb-3' style={{color:"#000"}}>Where to Find Us?</div>
                    <div className='d-flex flex-column' style={{color:"#7B7B7B"}}>
                        <div>
                            Fill up the form if you 
                            have any questions, 
                            and our team will get
                            back to you within 24 
                            hours.
                        </div>
                        <div className='mt-5' style={{color:"#000"}}>For Sales Enquiry</div>
                        <div>
                            <div className='d-flex mt-4'>
                                <img src="images/contactUs-man.png" className='contactUs-man'/>
                                <div className='d-flex align-items-center'>Sumit Singhal | Global</div>
                            </div>
                            <div className='d-flex mt-2 justify-content-start align-items-center'>                            
                                <a className='contact-link' href="mailto:sumit@casewell.ae">
                                    <Email />
                                    sumit@casewell.ae 
                                </a>
                            </div>
                        </div>
                        <div className='d-flex mt-4 justify-content-start'>
                            {/* <img src="images/contactUs-location.png" className='me-4' style={{height:"30px"}} /> */}
                            <a href="https://goo.gl/maps/rd3ptptXKBaBphjk8" className='d-flex align-items-center contact-link' target="_blank">
                                {/* <Location /> */}
                                <img src="images/contactUs-location.png" className='me-4 ms-1' style={{height:"40px"}} />
                                <div>Sector 7, IMT Manesar, Gurgaon, India</div>
                            </a>
                        </div>
                        <div className='d-flex mt-5 pt-2 '>
                            <a href='https://www.linkedin.com/company/casewellin/' className='contactUs-logos '><LinkedIn/></a>
                            <a href='https://twitter.com/casewellin' className='contactUs-logos ms-4'><Twitter/></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

const ContactIcons = styled(LocalPhoneIcon)`
    color: #9A0000;
    font-size: 50px !important;
    margin-right:5px;
`

const Email = styled(EmailIcon)`
    color: #9A0000;
    font-size: 50px !important;
    margin-right:5px;

    &:hover{
        color: #CF0406;
        cursor: pointer;
    }
`

const Location = styled(LocationOnIcon)`
    color: #9A0000;
    font-size: 50px !important;
    margin-right:5px;
    padding-left: 0;
    &:hover{
        color: #CF0406;
        cursor: pointer;
    }
`

const LinkedIn = styled(LinkedInIcon)`
    color: #fff;
    font-size: 50px !important;
    &:hover{
        color: #CF0406;
        cursor: pointer;
    }
`

const Twitter = styled(TwitterIcon)`
    color: #fff;
    font-size: 50px !important;

    &:hover{
        color: #CF0406;
        cursor: pointer;
    }
`

export default ContactUs



{/* <div className='row ' >
<div className='col-12 col-md-7 contactUs-first-column' style={{background:"#F5F5F5"}}>
    <div className='row ' >
        <div className='col-6 d-flex flex-column contact-fields' >
            <div className='d-flex align-items-start'>
                First Name
                <img src="images/contactUs-star.png" />
            </div>
            <input type="text" />
        </div>
        <div className='col-6 d-flex flex-column contact-fields'>
            <div className='d-flex align-items-start'>
                Last Name
                <img src="images/contactUs-star.png" />
            </div>
            <input type="text" />
        </div>
    </div>
    <div className='row'>
        <div className='col-6 d-flex flex-column contact-fields'>
            <div className='d-flex align-items-start'>
                Email
                <img src="images/contactUs-star.png" />
            </div>
            <input type="text" />
        </div>
        <div className='col-6 d-flex flex-column contact-fields'>
            <div className='d-flex align-items-start'>
                Mobile
                <img src="images/contactUs-star.png" />
            </div>
            <input type="text" />
        </div>
    </div>
    <div className='d-flex flex-column'>
        <div className='d-flex align-items-start mb-2'>
            What is your enquiry about?
            <img src="images/contactUs-star.png" style={{height:"10px"}}/>
        </div>
        <div className='ps-4'>
            <div className='d-flex'>
                <input type="checkbox" className='me-3 contactUs-checkbox'/> Centralizer
            </div>
            <div className='d-flex'>
                <input type="checkbox" className='me-3 contactUs-checkbox'/> Float
            </div>
            <div className='d-flex'>
                <input type="checkbox" className='me-3 contactUs-checkbox'/> Stop Collar
            </div>
            <div className='d-flex'>
                <input type="checkbox" className='me-3 contactUs-checkbox'/> Accessories
            </div>
            <div className='d-flex'>
                <input type="checkbox" className='me-3 contactUs-checkbox'/> Others
            </div>
        </div>
    </div>
    <div className='mt-4'>
        All fields marked with an asterisk (<span style={{color:"#CE0002"}}>*</span>) are mandatory.
    </div>
    <div className='d-flex flex-column mt-4'>
        Message
        <textarea placeholder='Write Your Message...' className='mt-2 contactUs-message'></textarea>
    </div>
    <div className='d-flex pt-3'>
        <input type="checkbox" className='me-3 contactUs-checkbox'/> 
        I agree to the &nbsp;<Link to='/privacyPolicy' style={{color:"black"}}>Privacy Policy and Other T&C.</Link>
    </div>
    <div className='d-flex my-2 justify-content-end'>
        <button className='submit-button'>Send Message</button>
    </div>
</div> */}